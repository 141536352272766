<template>
  <a-modal width="80%" v-model="visible" :footer="null" :title="'还款'" :maskClosable="false">
    <a-table :columns="columns" :data-source="tableData" bordered :maskClosable="false" :pagination="false">
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="repayment" slot-scope="">
        <a-input-number id="inputNumber" v-model="value" :min="0" :max="usedAmount"/>
      </span>
    </a-table>
    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
      <a-button type="primary" @click="toSubmit">确认</a-button>
    </div>
  </a-modal>
</template>

<script>
const qs = require('qs')
export default {
  data() {
    return {
      limitId: '',
      value: 0,
      usedAmount: 0,
      visible: false,
      columns: [
        {
          title: '额度名称',
          dataIndex: 'name',
          align: 'center',
          key: 'name',
        },
        {
          title: '额度金额',
          dataIndex: 'limitAmount',
          key: 'limitAmount',
          align: 'center',
          scopedSlots: {
            customRender: 'amount',
          },
        },
        {
          title: '已用额度',
          dataIndex: 'usedAmount',
          key: 'usedAmount',
          align: 'center',
          scopedSlots: {
            customRender: 'amount',
          },
        },
        {
          title: '还款金额',
          dataIndex: 'repayment',
          align: 'center',
          key: 'repayment',
          scopedSlots: { customRender: 'repayment' },
        },
      ],
      tableData: [],
    }
  },
  methods: {
    // 提交
    toSubmit(data) {
      console.log(JSON.parse(JSON.stringify(data)))
      if (!this.value) {
        return this.$notification.error({ message: '请填写还款金额' })
      }
      const query = {
        id: this.limitId,
        limitAmount: this.value
      }
      this.axios.post('/api/dealer/limit/limitInfo/repayment', query).then(res => {
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.tableData = []
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
    setRowData(row) {
      this.visible = true
      this.limitId = row.id
      this.value = row.limitAmount - row.remainingAmount
      this.usedAmount = row.usedAmount
      this.tableData = []
      this.tableData.push(row)
    },
    toCancel() {
      this.visible = false
      this.limitId = ''
      this.value = 0
      this.usedAmount = 0
      this.tableData = []
    },
  },
}
</script>

<style scoped>
</style>